import React from 'react';
import '../Table.css';
import {DOCS} from "../../constants";

const DOCS_PAGE = `${DOCS}/overview/sustainability/`;
const statusClass = (remaining) => Math.round(remaining) >= 0 ? 'on-track' : 'overtime';

const overall_headers =
    <>
        <td>
            <a href={`${DOCS_PAGE}#total-hours`} target='_blank' rel='noopener noreferrer'>
                Total hours
            </a>

        </td>
        <td>
            <a href={`${DOCS_PAGE}#billable-hours`} target='_blank' rel='noopener noreferrer'>
                Billable hours
            </a>

        </td>
        <td>
            <a href={`${DOCS_PAGE}#non-billable-hours`} target='_blank' rel='noopener noreferrer'>
                Non-billable hours
            </a>
        </td>
        <td>
            <a href={`${DOCS_PAGE}#ratio-of-non-billable-hours`} target='_blank' rel='noopener noreferrer'>
                % non-billable
            </a>
        </td>
    </>;

const cell_headers =
    <>
        <td>
            <a href={`${DOCS_PAGE}#total-hours-cell`} target='_blank' rel='noopener noreferrer'>
                Total hours
            </a>
        </td>
        <td>
            <a href={`${DOCS_PAGE}#billable-hours-cell`} target='_blank' rel='noopener noreferrer'>
                Billable hours
            </a>
        </td>
        <td>
            <a href={`${DOCS_PAGE}#non-billable-hours-cell`} target='_blank' rel='noopener noreferrer'>
                Non-billable hours
            </a>
        </td>
        <td>
            <a href={`${DOCS_PAGE}#ratio-of-non-billable-hours-cell`} target='_blank' rel='noopener noreferrer'>
                % non-billable
            </a>
        </td>
        <td>
            <a href={`${DOCS_PAGE}#remaining-non-billable-hours`} target='_blank' rel='noopener noreferrer'>
                Remaining non-billable hours
            </a>
        </td>
    </>;

const cell_fields = accounts =>
    <>
        <td className={statusClass(accounts.remaining)}>
            {Math.round(accounts.remaining)}
        </td>
    </>;

const SustainabilityTable = ({accounts, view}) =>
    <div>
        <table className="table sustainability-table">
            <thead>
            <tr className="table-header">
                {
                    view === "cells"
                        ? overall_headers
                        : cell_headers
                }
            </tr>
            </thead>
            <tbody>
            <tr className="table-row">
                <td>
                    {Math.round(accounts.total)}
                </td>
                <td>
                    {Math.round(accounts.billable)}
                </td>
                <td>
                    {Math.round(accounts.non_billable_total)}
                </td>
                <td className={statusClass(accounts.remaining)}>
                    {Math.round(accounts.total_ratio)}% (max {Math.round(accounts.sustainability_target * 100)}%)
                </td>
                {view !== "cells" && cell_fields(accounts)}
            </tr>
            </tbody>
        </table>
        <div className="loading" align="left">
            <ul>
                <li>Total hours = billable hours + non-billable hours</li>
                <li>% non-billable = non-billable hours / total hours</li>
            </ul>
        </div>
    </div>;

export default SustainabilityTable;
