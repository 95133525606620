import React, {Component} from 'react';
import '../Table.css';
import {Link} from "react-router-dom";
import {PATH_JIRA_ISSUE} from "../../constants";

const nameColumn = {width: '20%'};  // 1 cell
const spilloverColumn = {width: '35%'};   // 1 cell
const newWorkColumn = {width: '45%'};   // 1 cell
const timeColumn = {width: '10%'};  // 5 cells -> 50% total
const unestimatedColumn = {width: '15%'};  // 2 cells -> 30% total
const totalRowPlaceholder = {width: '30%'};  // 2 * unestimatedColumn

const statusClass = (remaining) => Math.round(remaining) >= 0 ? '' : 'overcommitted';
const toHours = (seconds) => Math.round(seconds / 3600);
const accumulateTime = (list, fn) => {
    const accumulatedTime = list.reduce((acc, item) => acc + fn(item), 0);
    return toHours(accumulatedTime);
}


class Table extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showSpillovers: true,
            hideFlaggedTickets: false,
        }
    }

    // Work left = assignee time + review time
    calculateWorkLeft = (item) => {
        return item.current_remaining_assignee_time + item.current_remaining_review_time;
    }

    // Estimated spillover = Work left - (remaining sprint length - vacation time)
    calculateEstimatedSpillover = (item) => {
        return Math.max(this.calculateWorkLeft(item) - (
            item.current_sprint_length - item.current_vacation_time
        ), 0);
    }

    // Committed time = assignee time + review time + epic management time + remaining upstream time
    // If hideFlaggedTickets is true, then subtract flagged time from the total.
    calculateCommittedTime = (item) => {
        let total = item.future_assignee_time +
        item.future_review_time +
        item.future_epic_management_time +
        item.current_remaining_upstream_time;

        return this.state.hideFlaggedTickets ? total - item.flagged_time : total;
    }

    // Goal = next sprint length - vacation time
    calculateGoalTime = (item) => {
        return item.future_sprint_length - item.future_vacation_time;
    }

    // Remaining time = Goal - Committed - Estimated spillover
    calculateRemainingTime = (item) => {
        let remaining = this.calculateGoalTime(item) - this.calculateCommittedTime(item);
        return this.state.showSpillovers ? remaining - this.calculateEstimatedSpillover(item) : remaining;
    }

    render() {
        const spilloverToggle = (
            <input
                name="toggle-spillover-tickets"
                type="checkbox"
                checked={this.state.showSpillovers}
                onChange={() => this.setState({showSpillovers: !this.state.showSpillovers})}
            />
        );

        const flaggedTicketsToggle = (
            <input
                name="toggle-flagged-tickets"
                type="checkbox"
                checked={this.state.hideFlaggedTickets}
                onChange={() => this.setState({hideFlaggedTickets: !this.state.hideFlaggedTickets})}
            />
        );

        const {list, url} = this.props;

        return (<table className="table">
            <thead>
            <tr className="table-header">
                <td style={nameColumn}/>
                <td style={spilloverColumn}>
                    Active Sprint
                </td>
                <td style={newWorkColumn}>
                    Next Sprint (<abbr title="Hide flagged tickets.">{flaggedTicketsToggle} only accepted</abbr>, <abbr title="Include spillovers.">{spilloverToggle} include spillovers</abbr>)
                </td>
            </tr>
            <tr className="table-header">
                <td style={nameColumn}>
                    User
                </td>
                <td style={timeColumn}>
                    Work Left
                </td>
                <td style={timeColumn}>
                    Estimated spillover
                </td>
                <td style={unestimatedColumn}>
                    Unestimated
                </td>
                <td style={unestimatedColumn}>
                    Unestimated
                </td>
                <td style={timeColumn}>
                    Committed
                </td>
                <td style={timeColumn}>
                    Goal
                </td>
                <td style={timeColumn}>
                    Remaining
                </td>
            </tr>
            </thead>
            <tbody>
            {list.map(item =>
                <tr key={item.name} className="table-row">
                    <td style={nameColumn}>
                        <Link to={`${url}/user/${item.name}`}>{item.name}</Link>
                    </td>
                    <td style={timeColumn}>
                        <div class="summary">
                            {toHours(this.calculateWorkLeft(item))}
                        </div>
                        <div class="details">
                            <dl>
                                <dt>Assigned</dt> <dd>{toHours(item.current_remaining_assignee_time)}</dd>
                                <dt>Review</dt> <dd>{toHours(item.current_remaining_review_time)}</dd>
                            </dl>
                        </div>
                    </td>
                    <td style={timeColumn}>
                        <div class="summary">
                            {toHours(this.calculateEstimatedSpillover(item))}
                        </div>
                        <div class="details">
                            <dl>
                                <dt>Work left</dt> <dd>&nbsp;{toHours(this.calculateWorkLeft(item))}</dd>
                                <dt>Vacation in sprint</dt> <dd>&nbsp;{toHours(item.current_vacation_time)}</dd>
                                <dt>Hours in sprint</dt> <dd>({toHours(item.current_sprint_length)})</dd>
                            </dl>
                        </div>
                    </td>
                    <td style={unestimatedColumn}>
                        {item.current_unestimated.map(ticket =>
                            <li key={ticket.key}>
                                <a href={PATH_JIRA_ISSUE + ticket.key} title={ticket.summary} target="_blank" rel="noopener noreferrer">{ticket.key}</a>
                            </li>
                        )}
                    </td>
                    <td style={unestimatedColumn}>
                        {item.future_unestimated.map(ticket =>
                            <li key={ticket.key}>
                                <a href={PATH_JIRA_ISSUE + ticket.key} title={ticket.summary} target="_blank" rel="noopener noreferrer">{ticket.key}</a>
                            </li>
                        )}
                    </td>
                    <td style={timeColumn}>
                        <div class="summary">
                            {toHours(this.calculateCommittedTime(item))}
                        </div>
                        <div class="details">
                            <dl>
                                <dt>Assigned</dt> <dd>{toHours(item.future_assignee_time)}</dd>
                                <dt>Review</dt> <dd>{toHours(item.future_review_time)}</dd>
                                <dt>Epic</dt> <dd>{toHours(item.future_epic_management_time)}</dd>
                                <dt>Blocked</dt> <dd>{toHours(item.current_remaining_upstream_time)}</dd>
                            </dl>
                        </div>
                    </td>
                    <td style={timeColumn}>
                        <div class="summary">
                            {toHours(this.calculateGoalTime(item))}
                        </div>
                        <div class="details">
                            <dl>
                                <dt>Hours in sprint</dt> <dd>&nbsp;{toHours(item.future_sprint_length)}</dd>
                                <dt>Vacation</dt> <dd>({toHours(item.future_vacation_time)})</dd>
                            </dl>
                        </div>

                    </td>
                    <td style={timeColumn} className={statusClass(this.calculateRemainingTime(item))}>
                        <div class="summary">
                            {toHours(this.calculateRemainingTime(item))}
                        </div>
                        <div class="details">
                            <dl>
                                <dt>Goal</dt> <dd>&nbsp;{toHours(this.calculateGoalTime(item))}</dd>
                                <dt>Committed</dt> <dd>({toHours(this.calculateCommittedTime(item))})</dd>
                                <dt>Estimated Spillover</dt> <dd>({toHours(this.calculateEstimatedSpillover(item))})</dd>
                            </dl>
                        </div>
                    </td>
                </tr>,
            )}
            <tr className="table-row">
                <td style={nameColumn}>
                    <abbr title="Note that these might not be the accurate sums of the values above, because the calculations are performed on seconds and then they are rounded to hours.">
                        <b>Total</b>
                    </abbr>
                </td>
                <td style={timeColumn}>
                    {accumulateTime(list, this.calculateWorkLeft)}
                </td>
                <td style={timeColumn}>
                    {accumulateTime(list, this.calculateEstimatedSpillover)}
                </td>
                <td style={totalRowPlaceholder}/>
                <td style={timeColumn}>
                    {accumulateTime(list, this.calculateCommittedTime)}
                </td>
                <td style={timeColumn}>
                    {accumulateTime(list, this.calculateGoalTime)}
                </td>
                <td style={timeColumn}>
                    {accumulateTime(list, this.calculateRemainingTime)}
                </td>
            </tr>
            </tbody>
        </table>);
    }
}

export default Table;
